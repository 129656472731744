import {ENTIRY_TYPE_TO_CLICK_LINK} from '../../constants/pdpRelatedProducts';

export const fireProductInteractionGA4Event = (
  action,
  clickLink,
  styleId = null
) => {
  window.dataLayer.push({
    click_link: clickLink,
    click_section: 'pdp related items',
    event: 'pdp_interaction',
    interaction_action: action,
    ...(styleId && {product_style_id: styleId})
  });
};

export const fireGA4ClickEvent = ({entityId, entityType}) => {
  const clickLink = ENTIRY_TYPE_TO_CLICK_LINK[entityType];

  if (!clickLink || !entityId) {
    return;
  }

  fireProductInteractionGA4Event('module click', clickLink, entityId);
};

export const fireGA4ClickEventWithCallback = ({callback, event}) => {
  const clickLink = ENTIRY_TYPE_TO_CLICK_LINK[event.entityType];

  window.dataLayer.push({
    click_link: clickLink,
    click_section: 'pdp related items',
    event: 'pdp_interaction',
    eventCallback: callback,
    eventTimeout: 1500,
    interaction_action: 'module click',
    product_style_id: event.entityId
  });
};

export const fireGA4DisplayEvent = ({entityType}) => {
  const clickLink = ENTIRY_TYPE_TO_CLICK_LINK[entityType];

  if (!clickLink) {
    return;
  }

  fireProductInteractionGA4Event('module display', clickLink);
};
